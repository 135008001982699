import { useEffect, useState } from "react";
import URI from "urijs";
import { BASE_API } from './config';

import { useDispatch, useSelector } from "react-redux";
import { formatTime } from "./Timer";
import Trophy from "./images/logomark.svg";
import TrophyDark from "./images/logomark-dark.svg";
import Modal from "./Modal";
import { useDarkMode } from "usehooks-ts";
import { setVote } from "./store";

export const onShare = async (elapsedTime, mode, battleCode) => {
  // on mobile use navigator.share on desktop use navigator.clipboard.writeText
  const crc = new Int32Array(new TextEncoder().encode(`${elapsedTime}${new Date().getDate()}`)).reduce((a, b) => a ^ b);

  const url = new URI(window.location.origin)
    .addSearch('t', elapsedTime)
    .addSearch('c', crc)
    .addSearch('share');

  let text = `J'ai réussi le Motif du jour en ${formatTime(elapsedTime, true)} !`;

  if (mode === 'battle') {
    text = `Je te défie à Motif ! Essaye de résoudre la grille plus vite que moi en moins de ${formatTime(elapsedTime, true)} !`;

    url.addSearch('mode', 'battle');
    url.addSearch('battle_code', battleCode);
  }

  if (navigator.share) {
    try {
      navigator.share({
        title: 'Motif',
        text: text,
        url: url.toString(),
      });
    } catch (err) {}
  } else {
    try {
      await navigator.clipboard.writeText(text + " " + url.toString());
      alert('Lien copié dans le presse-papier');
    } catch (err) {}
  }

  // call ogImage api endpoint to generate sharing image in redis cache
  fetch(new URI(BASE_API)
    .segment('images')
    .addSearch('duration', elapsedTime)
    .addSearch('c', crc)
    .toString());
};

const Success = ({ onClose, isEmbedded, competingTime, date, isOpened, onMoreGrids, onHasShared, mode, answerBattle }) => {
  const dispatch = useDispatch();
  const elapsedTime = useSelector(state => state.app.elapsedTime);
  const avgTime = useSelector(state => state.app.avgTime);
  const { isDarkMode } = useDarkMode();
  const vote = useSelector(state => state.app.vote);
  const battleCode = useSelector(state => state.app.battleCode);
  const success = useSelector(state => state.app.success);

  const [currentActiveGraphItem, setCurrentActiveGraphItem] = useState("yours");

  if (!success)
    return null;

  // define grid complexity
  const AVERAGE_TIME = avgTime || 300;

  // we compute the faster time and the slower time from the AVERAGE_TIME using the day of the year as a seed
  let fasterTime = Math.round(AVERAGE_TIME / 2);
  let slowerTime = Math.round(AVERAGE_TIME * 1.8);

  if (fasterTime > elapsedTime)
    fasterTime = Math.max(0, elapsedTime - 19);

  if (slowerTime < elapsedTime) {
    const slowerIncrement = Math.floor(Math.random() * 300) + 300;
    slowerTime = elapsedTime + slowerIncrement;
  }

  const timesSpread = slowerTime - fasterTime; // time spread between faster and slower time, in seconds
  const graphDivisions = 100 / timesSpread; // how many percent each second represents

  // find in which percent the user is
  const percent = 100 - Math.round((elapsedTime - fasterTime) / timesSpread * 100);

  const graphItems = [
    {
      label: "La + rapide",
      time: fasterTime,
      percent: 0,
      type: "faster",
    },
    {
      label: "La + lente",
      time: slowerTime,
      percent: 100,
      type: "slower",
    },
    {
      label: "Ta partie",
      time: elapsedTime,
      percent: graphDivisions * (elapsedTime - fasterTime),
      type: "yours",
    },
  ];

  if (competingTime) {
    graphItems.push({
      label: "Ton adversaire",
      time: competingTime,
      percent: graphDivisions * (competingTime - fasterTime),
      type: "theirs",
    });
  }

  const onVote = async (score) => {
    try {
      const url = new URI(BASE_API)
        .segment('rankGrid')
        .toString();

      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          grid: date,
          score,
        }),
      });

      dispatch(setVote(score));
    } catch (err) {
      console.error(err);
    }
  };

  const getVote = (score) => {
    switch (score) {
      case -1:
        return {
          emoji: "😐",
          label: "Déçu"
        };
      case 0:
        return {
          emoji: "🙂",
          label: "Pas mal"
        };
      case 1:
        return {
          emoji: "🤩",
          label: "C'était top !"
        };
      default:
        return "";
    }
  }

  if (mode === 'battle') {
    return (
      <Modal
        className="Success"
        isOpened={isOpened}
        onClose={onClose}
        logo={false}
        footer={
          <>
            {!answerBattle && (
              <div className="ButtonGroup ButtonGroup--horizontal">
                <div
                  className="ActionBtn Button--primary"
                  onClick={() => (
                    onShare(elapsedTime, mode, battleCode),
                    onHasShared()
                  )}
                >
                  Partager
                </div>
              </div>
            )}

            {answerBattle && (
              <div className="ButtonGroup ButtonGroup--horizontal">
                <div
                  className="ActionBtn Button--primary"
                  onClick={() => window.location = "/"}
                >
                  Jouer au Motif du jour
                </div>
              </div>
            )}
          </>
        }
      >
        <>
          <div className="Success-Icon">
            <img src={isDarkMode ? TrophyDark : Trophy } alt="Le masque Motif" />
          </div>
          <h1>Bravo !</h1>
          <p>Tu as réussi ce <strong>Motif</strong> en</p>
          <p className="Success-ElapsedTime"><strong>{formatTime(elapsedTime)}</strong></p>

          {!answerBattle && (
            <div className="Success-Comparison">
              Récupère le lien de partage pour défier un ami !
            </div>
          )}

          {answerBattle && (
            <div className="Success-Comparison">
              Tu as relevé le défi de ton ami !

              {(competingTime < elapsedTime) && (
                <p>Malheureusement, tu ne l'as pas battu... Tu feras mieux la prochaine fois !</p>
              )}

              {(competingTime > elapsedTime) && (
                <p>Tu as été plus rapide que lui ! Tu as mérité un peu de repos ;)</p>
              )}

            </div>
          )}

          {mode === `battle` && (
            <div style={{ marginTop: "25px" }}><a href={`/?mode=battle&leaderboard=true&battle_code=${battleCode}`} target="_blank">Consulter le classement du défi</a></div>
          )}
        </>
      </Modal>
    );
  }

  return (
    <Modal
      className="Success"
      isOpened={isOpened}
      onClose={onClose}
      logo={false}
      footer={
        <>
          <div className="ButtonGroup ButtonGroup--horizontal">
            <div
              className="ActionBtn Button--primary"
              onClick={() => (
                onShare(elapsedTime, mode, battleCode),
                onHasShared()
              )}
            >
              Partager
            </div>
            {!isEmbedded &&
              <div
                className="ActionBtn"
                onClick={() => (
                  onClose(),
                  onMoreGrids()
                )}
              >
                Jouer d'autres grilles
              </div>
            }
          </div>
        </>
      }
    >
      <>
        <div className="Success-Icon">
          <img src={isDarkMode ? TrophyDark : Trophy } alt="Le masque Motif" />
        </div>
        <h1>Bravo !</h1>
        <p>Tu as réussi le <strong>Motif</strong> du jour en</p>
        <p className="Success-ElapsedTime"><strong>{formatTime(elapsedTime)}</strong></p>

        <div className="Success-Comparison">
          {!competingTime && (
            <p>Tu es plus rapide que <strong>{percent}%</strong> des joueurs aujourd'hui.</p>
          )}
          {(competingTime && (competingTime < elapsedTime)) && (
            <p>Tu n'as pas battu ton adversaire mais tu es plus rapide que <strong>{percent}%</strong> des joueurs aujourd'hui.</p>
          )}
          {(competingTime && (competingTime > elapsedTime)) && (
            <p>Tu a été plus rapide que ton adversaire et <strong>{percent}%</strong> des joueurs aujourd'hui.</p>
          )}
          <div className="Comparison-Graph">
            <div className="Comparison-GraphBar-Wrapper">
              <div className="Comparison-GraphBar" />
              {graphItems.map((item, index) => (
                <div
                  key={index}
                  className={`Comparison-GraphBar-Item Comparison-GraphBar-Item--${item.type} ${currentActiveGraphItem === item.type ? "Comparison-GraphBar-Item--active" : ""}`}
                  style={{
                    left: item.percent + "%",
                  }}
                >
                  <div className="Comparison-GraphBar-Item-Mark"
                    onMouseEnter={() => setCurrentActiveGraphItem(item.type)}
                  />
                  <div
                    className="Comparison-GraphBar-ItemLabelWrapper"
                    style={{
                      textAlign: item.percent > 50 ? "right" : "left",
                      transform: item.percent > 50 ? "translateX(calc(-100% + 1ch))" : "translateX(-1ch)",
                      borderWidth: item.percent > 50 ? "0 1px 0 0" : "0 0 0 1px",
                    }}
                    onMouseEnter={() => setCurrentActiveGraphItem(item.type)}
                  >
                    <div className="Comparison-GraphBar-ItemLabel">{item.label}</div>
                    <div className="Comparison-GraphBar-ItemTime">{formatTime(item.time)}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="Success-Poll">
          <div className="Success-Poll-Title">As-tu aimé le Motif du jour ?</div>

          {(vote === false || typeof vote === 'undefined') && (
            <div className="Poll-Buttons">
              {[-1, 0, 1].map((value) => {
                const vote = getVote(value)

                return (
                  <div className="Button" key={value} title={vote.label} onClick={() => onVote(value)}>{vote.emoji}</div>
                )}
              )}
            </div>
          )}
          {vote !== false && (
            <>
            <div className="Success-Poll-Thanks">
              <div className="text-small">Tu as voté {getVote(vote).emoji}, merci !</div>
            </div>
            {(vote === -1) &&
              <>
                <div className="text-small mt-1">As-tu des suggestions pour améliorer Motif ?</div>
                <div className="text-small mt-1"><a href="https://xtilt3wfkot.typeform.com/to/EaIc2y6u" target="_blank">Tu peux nous les partager ici</a>.</div>
              </>
            }
            </>
          )}
        </div>
      </>
    </Modal>
  )
};

export default Success;