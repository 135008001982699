import Modal from "../Modal";

const Help = () => {
  return (
    <Modal className="Help large" isOpened={true}>
      <h1>Aide</h1>
      <p>Le but du jeu est de remplir la grille avec les mots correspondant aux définitions. Pour cela, utilisez le clavier virtuel sur mobile ou le clavier physique de votre ordinateur.</p>
      <p>Les mots sont en grande majorité des mots du dictionnaire Français, de 2 à 5 lettres. Il peut y avoir des noms propres ou références à l'actualité.</p>
      <p>Si vous êtes bloqué, vous pouvez utiliser les coups de pouce en bas de l'écran.</p>
      <p>Vous pouvez aussi activer la vérification automatique pour valider les mots au fur et à mesure.</p>
      <p>Enfin, si vous avez terminé, partagez votre temps de résolution avec vos amis/proches !</p>

      <div style={{ marginTop: '25px' }}>
        <a href="/" className="Button Button--primary">Retour au jeu</a>
      </div>
    </Modal>
  )
};

export default Help;