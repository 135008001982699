import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { formatTime } from "./Timer";

const CountDown = ({ type }) => {
  const [countdown, setCountdown] = useState(null);

  useEffect(() => {
    const tick = () => {
      const now = dayjs();
      const nextDay = now.add(1, 'day').startOf('day');

      setCountdown(nextDay.diff(now, 'second'));
    };

    const interval = setInterval(tick, 1000);
    tick();

    return () => clearInterval(interval);
  }, []);

  if (type === "short") {
    return (
      <div className="Countdown">
        Plus que {formatTime(countdown)} avant le prochain.
      </div>
    );
  }

  return (
    <div className="Countdown">
      La prochaine grille sera en ligne dans <span className="Time">{formatTime(countdown)}</span>.
    </div>
  );
};

export default CountDown;