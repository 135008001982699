import { useEffect, useState } from "react";
import Modal from "../Modal";
import URI from "urijs";
import { BASE_API } from "../config";
import { formatTime } from "../Timer";
import dayjs from "dayjs";

let fetchedOnce = false;

const Leaderboard = ({ battleCode }) => {
  const [leaderboard, setLeaderboard] = useState(false);
  const [battle, setBattle] = useState({});

  // retrieve battle learnboard on success
  useEffect(() => {
    if (!battleCode) return;
    if (leaderboard) return;
    if (fetchedOnce) return;

    const fetchLeaderboard = async () => {
      fetchedOnce = true;

      try {
        const url = new URI(BASE_API)
          .segment('battle')
          .segment('results')
          .addSearch('battle_code', battleCode)
          .toString();

        const response = await fetch(url);
        const data = await response.json();

        setLeaderboard(data.leaderboard);
        setBattle(data.battle);
      } catch (err) {
        console.error(err);
      }
    }

    fetchLeaderboard();
  }, [leaderboard]);

  return (
    <Modal className="Leaderboard large" isOpened={true}>
      <h1>Classement du défi de {battle?.username} (#{battle?.battle_code})</h1>

      {!leaderboard && <p>Chargement...</p>}

      {leaderboard && (
        <div className="LeaderboardList">
          {leaderboard.sort((a, b) => a.elapsedTime - b.elapsedTime).map((player, index) => (
            <div key={index} className="LeaderboardItem" style={{ marginBottom: "15px" }}>
              <div>
                <span>{index + 1}.&nbsp;</span>
                <span><strong>{player.username}</strong>&nbsp;</span>
              </div>

              <div style={{ marginTop: "5px", marginLeft: "50px" }}>
                {player.elapsedTime ? (<span>{formatTime(player.elapsedTime)}</span>) : (<span>en attente</span>)}
                <span>&nbsp;•&nbsp;{dayjs.unix(player.startedAt).format('dddd D MMMM à HH:mm')}</span>
              </div>
            </div>
          ))}
        </div>
      )}

      <div style={{ marginTop: '25px' }}>
        <a href="/?mode=battle" className="Button Button--primary">Créer un nouveau défi</a>
      </div>
    </Modal>
  )
};

export default Leaderboard;