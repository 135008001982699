import dayjs from "dayjs";
import CountDown from "./Countdown";
import { capitalizeFirstLetter } from "./utils/letters";
import Modal from "./Modal";

const getLastGrids = (days = 5) => {
  const data = [];

  for (let i = 1; i <= days; i++) {
    const date = dayjs().subtract(i, 'day').format('YYYY-MM-DD');
    let success = false;

    try {
      if (localStorage.getItem(`motif:${date}`)) {
        const data = JSON.parse(localStorage.getItem(`motif:${date}`));

        if (data?.app?.success)
        success = true;
      }
    } catch (e) {}

    data.push({
      date,
      success,
    });
  }

  return data;
}

const motivationChoices = [
  'A toi de jouer !',
  'En combien de temps le feras-tu ?',
  'Tu tentes ce Motif ?',
];

const MoreGrids = () => {
  const previousGrids = getLastGrids();

  const isiOS = navigator.userAgent.includes("Mac") && "ontouchend" in document;
  const isAndroid = navigator.userAgent.includes("Android") && "ontouchend" in document;

  return (
    <div className="MoreGrids-List">

        <div className="MoreGrids-Card MoreGrids-Card--Upcoming" onClick={() => {
            window.location.href = `/`;
          }}>
          <div className="MoreGrids-Card-Icon">🕒</div>
          <div className="MoreGrids-Card-Title">
            <CountDown />
          </div>
        </div>

        <h3>Motifs des 5 derniers jours</h3>
        {previousGrids.map((grid, index) => (
          <div key={index} className={`MoreGrids-Card MoreGrids-Card--Clickable ${grid.success ? 'MoreGrids-Card--Done' : 'MoreGrids-Card--NotDone'}`} onClick={() => {
            window.location.href = `/${grid.date}/`;
          }}>
            <div className="MoreGrids-Card-Icon">📅</div>

            <div className="MoreGrids-Card-Title">
              {capitalizeFirstLetter(dayjs(grid.date).format('dddd D'))}
            </div>

            {grid.success && (
              <div className="MoreGrids-Card-Description">Tu l'as déjà terminé.</div>
            )}

            {!grid.success && (
              <div className="MoreGrids-Card-Description">
                {motivationChoices[Math.floor(Math.random() * motivationChoices.length)]}
              </div>
            )}

          </div>
        ))}

        <h3>Toutes les archives</h3>
        {(!isiOS && !isAndroid) && (
          <p>Les grilles précédentes sont disponibles dans les applications Motif.</p>
        )}
        {!isAndroid && (
          <div className="MoreGrids-Card MoreGrids-Card--Clickable" onClick={() => {
            window.open('https://apps.apple.com/fr/app/motif-les-mots-crois%C3%A9s/id6479329303', '_blank');
          }}>
            <div className="MoreGrids-Card-Icon">📱</div>
            <div className="MoreGrids-Card-Title">
              {isiOS && "Tu trouveras toutes les archives dans l\'appli iOS ici"}
              {!isiOS && "Motif est sur l'App Store"}
            </div>
          </div>
        )}
        {!isiOS && (
          <div className="MoreGrids-Card MoreGrids-Card--Clickable" onClick={() => {
            window.open('https://play.google.com/store/apps/details?id=com.omagad.motif', '_blank');
          }}>
            <div className="MoreGrids-Card-Icon">📱</div>
            <div className="MoreGrids-Card-Title">
              {isAndroid && "Tu trouveras toutes les archives dans l'appli Android ici"}
              {!isAndroid && "Motif est sur le Play Store"}
            </div>
          </div>
        )}

    </div>
  );
}

export default MoreGrids;