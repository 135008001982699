import { useEffect } from 'react';
import LoadingLogo from './images/logomark.svg'
import LoadingLogoDark from './images/logomark-dark.svg'
import { useDarkMode } from 'usehooks-ts';

const Loader = ({ type, text }) => {
  const { isDarkMode } = useDarkMode()

  useEffect(() => {
    const img = document.querySelector('.Loading img');
    let deg = 0;
    const interval = setInterval(() => {
      deg = deg + 45 >= 360 ? 0 : deg + 45;
      img.style.transform = `rotate(${deg}deg)`;
    }, 500);

    return () => {
      clearInterval(interval);
    }
  }, []);

  if (type === 'simple') {
    return (
      <div className="Loading Loading--small">
        <img src={isDarkMode ? LoadingLogoDark : LoadingLogo} alt="Chargement du Motif" />
      </div>
    )
  }

  return (
    <div className="Loading">
      <img src={isDarkMode ? LoadingLogoDark : LoadingLogo} alt="Chargement du Motif" />
      {text && text}
    </div>
  )

}

export default Loader