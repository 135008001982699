import { useEffect, useState } from "react";
import Modal from "./Modal";

let once = false;

const Whoops = ({ emitter, autoVerify, onAutoVerify }) => {
  const [displayed, setDisplayed] = useState(false);

  useEffect(() => {
    const onWhoops = () => {
      if (once) return;
      once = true;
      setDisplayed(true);
    }

    emitter.on('whoops', onWhoops);

    return () => {
      emitter.off('whoops', onWhoops);
    }
  }, [emitter]);

  return (
    <Modal
      className="Whoops"
      isOpened={displayed}
      onClose={() => setDisplayed(false)}
      footer={
        <div className="BtnGroup">
          <div className="ActionBtn Button--primary" onClick={() => setDisplayed(false)}>
            Reprendre la partie
          </div>
          <div className="ActionBtn" onClick={() => {
            if (!autoVerify)
              onAutoVerify();

            setDisplayed(false);
          }}>
            Activer l'auto vérification
          </div>
        </div>
      }
    >
      <h1>Tu y es presque !</h1>
      <p>Ta grille est pleine mais au moins une case est à côté de la plaque. Zut...</p>
    </Modal>
  )
};

export default Whoops;