import { useMediaQuery } from "usehooks-ts";
import Modal from "./Modal";
import { formatTime } from "./Timer";
import { usePostHog } from 'posthog-js/react';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUsername } from "./store";

const Splash = ({ competingTime, onClose, isOpened, onDownload, mode, battleCode }) => {

  const dispatch = useDispatch();

  const isiOS = navigator.userAgent.includes("Mac") && "ontouchend" in document;
  const isAndroid = navigator.userAgent.includes("Android") && "ontouchend" in document;
  const posthog = usePostHog()
  const [CTA, setCTA] = useState(mode === 'battle' ? `Je suis prêt !` : 'Commencer');
  const username = useSelector(state => state.app.username);

  // media query to detect mobile
  const isMobile = useMediaQuery('(max-width: 768px)', {
    defaultValue: true, // important to set defaultValue to true because of MoreGrids
  })
  const isTablet = useMediaQuery('(max-width: 1280px)', {
    defaultValue: true, // important to set defaultValue to true because of MoreGrids
  })

  const onCloseSplash = () => {
    if (mode === 'battle' && !username.trim().length) {
      alert('Tu dois entrer un pseudo pour commencer la bataille !');
      return;
    }

    onClose();

    if (mode === 'battle')
      setCTA('Génération de la grille de bataille...');
  }

  return (
    <Modal
      className={`Splash ${posthog.getFeatureFlag('splash_screen') === 'partial_modal' ? 'Modal--partial' : 'Modal--full'}`}
      isOpened={isOpened}
      logo={posthog.getFeatureFlag('splash_screen') !== 'partial_modal'}
      showClose={false}
      footer= {
        <>
          {mode === 'battle' && (
            <div className="ActionUsername">
              <input className="InputText" type="text" placeholder="Renseigne ton nom" value={username} onChange={(e) => dispatch(setUsername(e.target.value))} />
            </div>
          )}

          <div className={`ActionBtn Button--primary ${mode === 'battle' && !username.trim().length ? 'disabled' : 'enabled'}`} onClick={onCloseSplash}>
            {CTA}
          </div>

          {isiOS && mode !== 'battle' && <a
              href="https://apps.apple.com/fr/app/motif-mots-crois%C3%A9s-5x5/id6479329303"
              className="AppStoreBadge"
              onClick={onDownload}
            >
              Télécharger sur l'App Store
            </a>
          }

          {isAndroid && mode !== 'battle' && <a
              href="https://play.google.com/store/apps/details?id=com.omagad.motif"
              className="AppStoreBadge"
              onClick={onDownload}
            >
              Télécharger sur le Play Store
            </a>
          }

          {!isiOS && !isAndroid && mode !== 'battle' && (
            <div className="ActionBtnGroup">
              <a         
                href="https://apps.apple.com/fr/app/motif-mots-crois%C3%A9s-5x5/id6479329303"
                className="Button ActionBtn"
                onClick={onDownload}
              >
                Disponible sur App Store
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.omagad.motif"
                className="Button ActionBtn"
                onClick={onDownload}
              >
                Disponible sur Play Store
              </a>
            </div>
          )}

        </>
      }
      onClose={onCloseSplash}
    >
      {mode !== 'battle' && posthog.getFeatureFlag('splash_screen') === 'partial_modal'  && (
        <>
          <h1>Le mini mots croisés Motif</h1>
          <p>Prêt à résoudre la grille ?</p>
        </>
      )}

      {mode !== 'battle' && posthog.getFeatureFlag('splash_screen') !== 'partial_modal'  && (
        <>
          <h1>Bienvenue sur Motif, le mini mots croisés !</h1>
          {!isTablet && !isMobile && (
            <>
              <p>Motif, c'est :</p>
              <ul>
                <li><span>📅</span>  Une nouvelle grille par jour.</li>
                <li><span>🛟</span>  Des coups de pouces pour te débloquer rapidement.</li>
                <li><span>⏳</span>  Un chronomètre pour te challenger.</li>
              </ul>
            </>
          )}
          {!competingTime && (
            <p>Arriveras-tu à décrypter tous les indices et remplir la grille le plus vite possible ?</p>
          )}
          {competingTime && (
              <p>Arriveras-tu à décrypter tous les indices et remplir la grille plus vite que les <strong>{formatTime(competingTime)}</strong> de ton adversaire ?</p>
          )}
        </>
      )}

      {mode === 'battle' && !battleCode && (
        <>
          <h1>Commencer une nouvelle bataille !</h1>
          <p>En mode bataille, votre partie est enregistrée et chronométrée sur une grille générée aléatoirement.</p>
          <p>Une fois résolue, partager votre lien à votre famille et amis afin qu'ils puissent se mesurer à vous.</p>
          <p>Que le plus rapide l'emporte !</p>
        </>
      )}

      {mode === 'battle' && battleCode && (
        <>
          <h1>Relever le défi !</h1>
          <p>Ton ami te défie à Motif.</p> 
          <p>La partie est chronométrée, que plus rapide l'emporte !</p>
        </>
      )}
    </Modal>
  )
};

export default Splash;