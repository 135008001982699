import Modal from "../Modal";

const Privacy = () => {
  return (
    <Modal className="Privacy large" isOpened={true}>
      <h1>Politique de Confidentialité de Motif</h1>

      <p>Mise à jour : [19 mars 2024]</p>

      <p>Merci d'avoir choisi Motif, le jeu de mots-croisés simple et divertissant. La confidentialité de nos utilisateurs est une priorité pour nous. Cette politique de confidentialité explique quelles informations nous collectons lorsque vous utilisez notre application et comment nous les utilisons.</p>

      <p><strong>Données Personnelles :</strong></p>
      <p>Motif ne collecte aucune information personnelle identifiable. Vous pouvez jouer à nos mots-croisés sans créer de compte, et aucune information personnelle n'est nécessaire pour utiliser notre application.</p>

      <p><strong>Suivi Publicitaire :</strong></p>
      <p>Nous ne suivons pas votre activité de jeu à des fins publicitaires. Motif est entièrement dépourvu de publicités et de trackers publicitaires. Nous croyons en une expérience de jeu sans interruption ni intrusion.</p>

      <p><strong>Stockage des Données :</strong></p>
      <p>Aucune donnée utilisateur n'est stockée sur nos serveurs. Toutes les données relatives à votre jeu sont stockées uniquement localement sur votre appareil. Nous n'avons pas accès à ces données et ne les partageons avec aucun tiers.</p>

      <p><strong>Conformité à la GDPR :</strong></p>
      <p>Motif est en conformité avec le Règlement Général sur la Protection des Données (GDPR). Nous respectons les droits des utilisateurs en matière de confidentialité et de protection des données.</p>

      <p><strong>Serveurs en France :</strong></p>
      <p>Nos serveurs sont situés en France, ce qui signifie que toutes les opérations de traitement de données sont effectuées dans l'Union Européenne, garantissant ainsi un niveau élevé de protection des données.</p>

      <p><strong>Contact :</strong></p>
      <p>Si vous avez des questions concernant notre politique de confidentialité ou si vous souhaitez exercer vos droits en vertu du GDPR, veuillez nous contacter à l'adresse suivante : [hello@playmotif.fr].</p>

      <p>En utilisant Motif, vous acceptez notre politique de confidentialité. Nous nous réservons le droit de mettre à jour cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page.</p>

      <p>Merci de jouer à Motif !</p>

      <div style={{ marginTop: '25px' }}>
        <a href="/" className="Button Button--primary">Retour au jeu</a>
      </div>
    </Modal>
  )
};

export default Privacy;