// send postMessage to parent window
const postMessageForWeb = (data) => {
  try {
    window.parent.postMessage(JSON.stringify(data), '*');
  } catch (e) {}
}

// for native apps, we inject a custom postMessage function
// the function is injected by the native app
// it handles directly the data no need to stringify it
// otherwise we fallback to the classic postMessage that SHOULD be available in the webview
// but currently only work on iOS and not on Android :(
const postMessageForNative = (data, isRetry = false) => {
  try {
    // TODO: remove this when iOS app is updated with the new _customPostMessage
    window.ReactNativeWebView.postMessage(JSON.stringify(data), '*');

    if (window._customPostMessage) {
      window._customPostMessage(data);
      return;
    }

    // try to fallback to the classic postMessage we should have in the webview (but not working on Android)
    if (isRetry) {
      // window.ReactNativeWebView.postMessage(JSON.stringify(data), '*');
      return;
    }

    // retry mechanism in 500ms because sometimes (often) the _customPostMessage is not yet injected as we try to post a message
    setTimeout(() => {
      if (window._customPostMessage)
        postMessageForNative(data, true)
    }, 500);
  } catch (e) {}
}

export const postMessage = (data) => {
  data.source = 'Motif';

  console.log('postMessage', data);

  postMessageForWeb(data);
  postMessageForNative(data);
}