import dayjs from 'dayjs';

import { applyMiddleware, compose } from 'redux';
import { configureStore, createSlice } from '@reduxjs/toolkit';
import persistState from 'redux-localstorage';
import { getCurrentDay, getMode } from './utils/getCurrentDay';

const getKey = (mode, date) => {
  if (mode === 'battle')
    return `motif:battle`;

  return date ? `motif:${date}` : 'motif:current';
}

const getPesistStates = () => {
  const persistStates = [];

  if (getMode()?.mode !== 'battle') {
    persistStates.push(persistState(null, {
      key: getKey(getMode(), getCurrentDay()),
      slicer: () => (state) => ({
        app: state.app,
      }),
    }));

    persistStates.push(persistState(null, {
      key: 'motif:stats',
      slicer: () => (state) => ({
        stats: state.stats,
      }),
    }));
  }

  return persistStates;
}

const enhancer = compose(
  applyMiddleware(),
  ...getPesistStates(),
);

const initialState = {
  definitions: {
    "accross": [],
    "down": [],
  },
  selected: { x: 0, y: 0 },
  grid: Array(5).fill(Array(5).fill('')),
  blackCells: [],
  history: [],
  username: '',
  opponentHistory: [],
  hintsKey: null,
  mode: `daily`,
  battleCode: null,
  battleUsername: '',
  hints: [],
  reveals: [],
  avgTime: null,
  selectedDirection: 'across',
  autoVerify: false,
  answer: '',
  elapsedTime: 0,
  seenSplash: false,
  pause: false,
  success: false,
  vote: false,
  statsPersisted: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setDefinitions: (state, action) => {
      state.definitions = action.payload;
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    setGrid: (state, action) => {
      state.grid = action.payload;
    },
    setSelectedDirection: (state, action) => {
      state.selectedDirection = action.payload;
    },
    setAnswer: (state, action) => {
      state.answer = action.payload;
    },
    setElapsedTime: (state, action) => {
      state.elapsedTime = action.payload;
    },
    setPause: (state, action) => {
      state.pause = action.payload;
    },
    setTick: (state) => {
      state.elapsedTime = state.elapsedTime + 1;
    },
    incrElapsedTime: (state, action) => {
      state.elapsedTime = state.elapsedTime + action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setSeenSplash: (state, action) => {
      state.seenSplash = action.payload;
    },
    setHints: (state, action) => {
      state.hints = action.payload;
    },
    setHintsKey: (state, action) => {
      state.hintsKey = action.payload;
    },
    setAutoVerify: (state, action) => {
      state.autoVerify = action.payload;
    },
    setAvgTime: (state, action) => {
      state.avgTime = action.payload;
    },
    setReveals: (state, action) => {
      state.reveals = action.payload;
    },
    addReveal: (state, action) => {
      state.reveals.push(action.payload);
    },
    setVote: (state, action) => {
      state.vote = action.payload;
    },
    setStatsPersisted: (state, action) => {
      state.statsPersisted = action.payload;
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setBattleCode: (state, action) => {
      state.battleCode = action.payload;
    },
    setOpponentHistory: (state, action) => {
      state.opponentHistory = action.payload;
    },
    pushHistory: (state, action) => {
      state.history.push(action.payload);
    },
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setBattleUsername: (state, action) => {
      state.battleUsername = action.payload;
    },
    reset: () => {
      // use initialState to reset the state
      return initialState;
    },
  },
});

export const {
  setDefinitions,
  setSelected,
  setGrid,
  setSelectedDirection,
  setAnswer,
  setTick,
  setPause,
  setSuccess,
  setSeenSplash,
  setAutoVerify,
  setHints,
  setHintsKey,
  incrElapsedTime,
  setAvgTime,
  setReveals,
  addReveal,
  setVote,
  setStatsPersisted,
  setMode,
  setBattleCode,
  setOpponentHistory,
  pushHistory,
  setUsername,
  setBattleUsername,
  reset
} = appSlice.actions;

const statsSlice = createSlice({
  name: 'stats',
  initialState: {
    totalGridsPlayed: 0,
    totalGridsCompleted: 0,
    currentStreak: 0,
    longestStreak: 0,
    lastGridCompleted: null,
    totalElapsedTime: 0,
    totalHintsUsed: 0,
  },
  reducers: {
    incrTotalGridsPlayed: (state) => {
      state.totalGridsPlayed = state.totalGridsPlayed + 1;
    },
    incrTotalGridsCompleted: (state) => {
      state.totalGridsCompleted = state.totalGridsCompleted + 1;

      // if last grid completed is yesterday, increment current streak
      if (state.lastGridCompleted === dayjs().subtract(1, 'day').format('YYYY-MM-DD')) {
        state.currentStreak++;
      } else {
        state.currentStreak = 1;
      }

      // update last grid completed date
      state.lastGridCompleted = dayjs().format('YYYY-MM-DD');

      // if current streak is greater than longest streak, update longest streak
      if (state.currentStreak > state.longestStreak)
        state.longestStreak = state.currentStreak;
    },
    incrTotalElapsedTime: (state, action) => {
      state.totalElapsedTime = state.totalElapsedTime + action.payload;
    },
    incrTotalHintsUsed: (state, action) => {
      state.totalHintsUsed = state.totalHintsUsed + (action?.value || 1);
    },
    reset: () => {
      // use initialState to reset the state
      return initialState;
    },
  },
});

export const {
  incrTotalGridsPlayed,
  incrTotalGridsCompleted,
  incrTotalElapsedTime,
  incrTotalHintsUsed,
} = statsSlice.actions;

export default configureStore({
  reducer: {
    app: appSlice.reducer,
    stats: statsSlice.reducer,
  },
  devTools: true,
  enhancers: () => [enhancer],
});