// TODO with black cells
let fallbackData = {"definitions":{"across":["Pareil, similaire","Raison ou thème récurrent","Lieu des combats de gladiateurs","Contenant pour transporter de l'eau"],"down":["Capitale du Pérou","Repose sur ton lit","Repas d'un bébé","Unité de poids en pierres précieuses","Ce qui brûle et éclaire"]},"answer":"0d20eb51746c9aaccb2ddac3d8040d0fa2b79ca2","blackCells":[[4,0],[0,1],[0,3],[0,4],[1,4]]};

// ANSWER
/*
L   #   T   #   #

I   D   E   M   #

M   O   T   I   F

A   R   E   N   E

#   S   E   A   U
*/

fallbackData = {"definitions":{"across":["Passions dévorantes","Vida les ordures","Action passée de se déplacer rapidement","Surnom donné à un Italien","Faisais usage de quelque chose dans le passé"],"down":["Diminué en intensité, passé de clair à sombre","Cri des chiens","Éprouva de l'incertitude","Futur simple de posséder","Isolés, à part les autres"]},"answer":"943df2ad987a2fb5b1c26bb7d298127e76ad5b50"};

fallbackData = {
  across: [ "skis", "emu", "tapir", "agite", "lises" ],
  down: [ "etal", "agi", "kepis", "imite", "sures" ],
  blackCells: [
    [ 0, 0 ], [ 1, 1 ]
  ],
  answer: "8b675ded092b8c647c891ff648ffe280279a2e72",
  definitions: {
    across: [ "Accessoires de montagne pour glisser sur la neige.",
      "Oiseau coureur d'Australie, plus petit que l'autruche.",
      "Animal d'Amérique du Sud, ressemble à un petit rhinocéros sans la corne.",
      "En mouvement, ne tient pas en place.",
      "Ce que tu fais avec un roman ou un journal."
    ],
    down: [ "Montre des produits sur un marché; pas en désordre, mais bien...",
      "Pas inerte, mais plutôt en action.", "Coiffures militaires portées sur la tête.",
      "Fait la même chose qu'un modèle ou une idole.",
      "Pas incertaines, mais plutôt confiantes et fondées."
    ]
  }
};

export default fallbackData;