import { sha1 } from 'js-sha1';
import URI from 'urijs';

import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setSelected, setSelectedDirection, setSuccess, setGrid, incrTotalGridsCompleted, incrTotalElapsedTime, setStatsPersisted, pushHistory } from './store';
import Loader from './Loader';
import { decrypt } from './utils/crypt';

import './Grid.css';
import Cell from './Cell';

import { moveToNextCell, moveToPreviousCell } from './utils/move';
import { belongToWordNumber, isWordCompleteByCell, mapGridNumbers } from './utils/grid';
import { useMediaQuery } from 'usehooks-ts';

import useSound from 'use-sound';
import SuccessSound from "./sounds/fanfare.mp3";
import { BASE_API } from './config';

const { doubledeleteonempty } = new URI().search(true);
const DOUBLEDELETEONEMPTY = !!doubledeleteonempty || false;

const Grid = ({ emitter, boardSize, boardRatio, isMobile, onSuccess, mode }) => {
  const dispatch = useDispatch();
  const [play] = useSound(SuccessSound, { volume: 0.5 });

  const grid = useSelector(state => state.app.grid);
  window.grid = grid;

  const selectedDirection = useSelector(state => state.app.selectedDirection);
  const selected = useSelector(state => state.app.selected);
  const answer = useSelector(state => state.app.answer);
  const success = useSelector(state => state.app.success);
  const pause = useSelector(state => state.app.pause);
  const gridNumbers = mapGridNumbers(grid);
  const gridRef = useRef(null);
  const currentWordNumber = belongToWordNumber(selected.x, selected.y, selectedDirection, grid);
  const elapsedTime = useSelector(state => state.app.elapsedTime);
  const hints = useSelector(state => state.app.hints);
  const hintsKey = useSelector(state => state.app.hintsKey);
  const history = useSelector(state => state.app.history);
  const opponentHistory = useSelector(state => state.app.opponentHistory);
  const battleCode = useSelector(state => state.app.battleCode);
  const username = useSelector(state => state.app.username);

  const isCompleted = () => grid.every(row => row.every(cell => cell !== ''));

  const onCompleted = async () => {
    const hash = sha1(grid.flat().join(''));

    if (hash !== answer) {
      emitter.emit('whoops');
      return;
    }

    play();

    // persist stats
    dispatch(setSuccess(true));
    dispatch(incrTotalGridsCompleted());
    dispatch(incrTotalElapsedTime(elapsedTime));
    dispatch(setStatsPersisted(true));

    if (mode === `battle`) {
      const data = await fetch(`${BASE_API}/battle/complete?battle_code=${battleCode}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          elapsedTime,
          history,
          username,
        }),
      });
    }

    onSuccess();
  };

  const onKeyPress = (key, isHint) => {
    if (success || pause) return;

    if (key === '{backspace}') {
      console.log('backspace')
      let deletePrevious = false;

      // if current cell is empty and DOUBLEDELETEONEMPTY is enabled, delete previous cell as well
      if (grid[selected.x][selected.y] === '' && DOUBLEDELETEONEMPTY)
        deletePrevious = true;

      dispatch(setGrid(grid.map((row, x) => row.map((cell, y) => (x === selected.x && y === selected.y && cell !== '#') ? '' : cell))));

      const [nextSelected, nextSelectedDirection] = moveToPreviousCell(selected, selectedDirection, grid);

      // if previous cell does not belong to a word, move again
      if (!belongToWordNumber(nextSelected.x, nextSelected.y, nextSelectedDirection, grid)) {
        const skip = moveToPreviousCell(nextSelected, nextSelectedDirection, grid);
        dispatch(setSelected(skip[0]));
        dispatch(setSelectedDirection(skip[1]));
        return;
      }

      dispatch(setSelected(nextSelected));
      dispatch(setSelectedDirection(nextSelectedDirection));

      // if deletePrevious is enabled, delete previous cell
      if (deletePrevious)
        dispatch(setGrid(grid.map((row, x) => row.map((cell, y) => (x === nextSelected.x && y === nextSelected.y && cell !== '#') ? '' : cell))));

      return;
    }

    const newGrid = grid.map((row, x) => row.map((cell, y) => (x === selected.x && y === selected.y) ? key : cell));
    dispatch(setGrid(newGrid));

    if (mode === `battle` && !isHint) {
      try {
        const move = {
          x: selected.x,
          y: selected.y,
          elapsedTime: elapsedTime,
          isValid: decrypt(hintsKey, hints[selected.x][selected.y]) === key,
        };

        dispatch(pushHistory(move));
      } catch (err) {}
    }

    // if word is complete, move to next word
    if (isWordCompleteByCell(selected.x, selected.y, selectedDirection, newGrid)) {
      const [newSelected, newDirection] = moveToNextCell(selected, selectedDirection, newGrid, true);

      dispatch(setSelected(newSelected));
      dispatch(setSelectedDirection(newDirection));
      return;
    }

    // select next cell depending on the direction
    const [nextSelected, nextSelectedDirection] = moveToNextCell(selected, selectedDirection, grid);

    // if next cell does not belong to a word, move again
    if (!belongToWordNumber(nextSelected.x, nextSelected.y, nextSelectedDirection, grid)) {
      const skip = moveToNextCell(nextSelected, nextSelectedDirection, grid);
      dispatch(setSelected(skip[0]));
      dispatch(setSelectedDirection(skip[1]));
      return;
    }

    dispatch(setSelected(nextSelected));
    dispatch(setSelectedDirection(nextSelectedDirection));
  };

  useEffect(() => {
    const customKeyPress = (data) => {
      if (typeof data === 'object' && data.hint) {
        onKeyPress(data.value, true);
        return;
      }

      onKeyPress(data);
    }

    emitter.on('keyPress', customKeyPress);

    return () => {
      emitter.off('keyPress', customKeyPress);
    }
  }, [selected, selectedDirection, grid]);

  useEffect(() => {
    if (!isCompleted()) return;
    if (success) return;

    onCompleted();
  }, [grid]);

  useEffect(() => {
    emitter.emit('gridLoaded');
  }, []);

  const onSelectCell = ({ x, y }) => {
    // do not select black cells
    if (grid[x][y] === '#') return;

    // change direction if no selected word in the current direction
    if (!belongToWordNumber(x, y, selectedDirection, grid))
      dispatch(setSelectedDirection(selectedDirection === 'across' ? 'down' : 'across'));

    // change direction if the same cell is selected and the other direction has a word
    if ((x === selected.x && y === selected.y) && belongToWordNumber(x, y, selectedDirection === 'across' ? 'down' : 'across', grid))
      dispatch(setSelectedDirection(selectedDirection === 'across' ? 'down' : 'across'));

    dispatch(setSelected({ x, y }));
  }

  let isScreenMedium = useMediaQuery('(min-width: 1024px)');
  let isScreenLarge = useMediaQuery('(min-width: 1280px)');
  let isScreenExtraLarge = useMediaQuery('(min-width: 1440px)');

  let gridWrapperSize = boardSize.width > boardSize.height ? boardSize.height : boardSize.width;

  // if display is horizontal, grid width should not be larger than 2/3 of the board width to keep space for definitions
  if (!isMobile && boardRatio > 1)
    gridWrapperSize = boardSize.width * 2 / 3 > boardSize.height ? boardSize.height : boardSize.width * 2 / 3;


  const gridWrapperStyle = {
    width: `${gridWrapperSize}px`,
    height: `${gridWrapperSize}px`,
  }

  let GRID_SPACING = 0;
  if (isScreenMedium) { GRID_SPACING = 20; }
  if (isScreenLarge) { GRID_SPACING = 40; }
  if (isScreenExtraLarge) { GRID_SPACING = 60; }
  let gridSize = gridWrapperSize - (GRID_SPACING * 2);

  const gridStyle = {
    width: `${gridSize}px`,
    height: `${gridSize}px`,
  }

  const cellSize = gridSize / grid.length;

  if (boardSize.width === 0 || boardSize.height === 0)
    return <Loader type="simple" />;

  return (
    <div className='GridWrapper' ref={gridRef} style={gridWrapperStyle}>
      <div className={`Grid ${success ? 'Grid--Success' : 'Grid--Playing'}`} tabIndex={0} style={gridStyle}>
        {grid.map((row, x) => (
          <div key={x} className="Row">
            {row.map((cell, y) => {
              const belongToWord = belongToWordNumber(x, y, selectedDirection, grid);
              const fromSelectedWord = belongToWord && currentWordNumber && belongToWord[0] === currentWordNumber[0];

              return <Cell
                key={y}
                x={x}
                y={y}
                opponentHistory={opponentHistory}
                elapsedTime={elapsedTime}
                value={cell}
                wordNumber={gridNumbers[x][y]}
                isSelected={selected.x === x && selected.y === y}
                isFromSelectedWord={fromSelectedWord}
                onClick={() => onSelectCell({ x, y })}
                size={cellSize}
              />
            })}
          </div>
        ))}
      </div>
    </div>
  )
};

export default Grid;