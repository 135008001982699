import URI from "urijs";
import Modal from "../Modal";

const About = () => {
  const { embed } = (new URI(window.location.href)).search(true);
  const isEmbedded = typeof embed !== 'undefined';

  return (
    <Modal className="About large" isOpened={true}>
      <h1>À propos</h1>

      <p>Cette application est développée sur leur temps libre par <a href="https://www.linkedin.com/in/tristandaeschner/" target="_blank">Tristan Daeshner</a> et <a href="https://www.linkedin.com/in/guillaumepotier/" target="_blank">Guillaume Potier</a></p>
      <p>Ce projet vise avant tout à s'amuser dans le code (Guillaume) et l'interface de jeu (Tristan), tout en répondant à une attente de leurs proches pour un jeu cérébral simple et rapide au quotidien à base de mots de la langue Française.</p>
      <p>Toutes les technologies utilisées sont Open Source et/ou libres de droits. Les définitions sont générées par Intelligence Artificielle et le plus souvent, relues par un humain (oui, c'est encore nécessaire).</p>
      <p>Un grand merci à <a href="https://github.com/jsgonsette/Wizium">Wizium</a> de Jean-Sébastien Gonsette et ses articles qui nous ont bien aidé dans la génération initiale de grilles.</p>
      <p>Si vous aimez le jeu et le processus créatif associé, merci de parler de nous, partager vos scores, télécharger l'application iOS et la noter positivement !</p>
      <p>Bon jeu !</p>

      {!isEmbedded && (
        <div style={{ marginTop: '25px' }}>
          <a href="/" className="Button Button--primary">Retour au jeu</a>
        </div>
      )}
    </Modal>
  )
};

export default About;