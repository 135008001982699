// we get the initial grid from the API with black cells
// and we generate a new grid matrix only with word numbers on cell word start
export const mapGridNumbers = (grid) => {
  const numbersGrid = Array(grid.length).fill(null).map(() => Array(grid.length).fill(null));
  let wordNumber = 0;

  // across definitions
  for (let i = 0; i < grid.length; i++) {
    for (let j = 0; j < grid.length; j++) {
      if (!isFirstCellOfWordWithDirection(i, j, 'across', grid))
        continue;

      wordNumber++;
      numbersGrid[i][j] = [wordNumber, 'across'];
    }
  }

  // down definitions
  for (let j = 0; j < grid.length; j++) {
    for (let i = 0; i < grid.length; i++) {
      if (!isFirstCellOfWordWithDirection(i, j, 'down', grid))
        continue;

      // if already a number, skip
      if (numbersGrid[i][j] !== null) {
        numbersGrid[i][j].push('down');
        continue;
      }

      wordNumber++;
      numbersGrid[i][j] = [wordNumber, 'down'];
    }
  }

  return numbersGrid;
}

// get word numbers map for easy access
export const getGridNumbersMap = (grid) => {
  const numbersGrid = mapGridNumbers(grid);

  // store word numbers in a map for easy access with keyw like '1-across'
  const map = {};

  for (let i = 0; i < grid.length; i++) {
    for (let j = 0; j < grid.length; j++) {
      if (numbersGrid[i][j] === null)
        continue;

      const [number, direction, direction2] = numbersGrid[i][j];
      map[`${number}-${direction}`] = { x: i, y: j };

      if (direction2)
        map[`${number}-${direction2}`] = { x: i, y: j };
    }
  }

  return map;
}

export const getDefinitionsArray = (grid) => {
  const numbersGrid = mapGridNumbers(grid);
  const definitions = { across: [], down: [] };

  // accross definitions
  for (let i = 0; i < grid.length; i++) {
    for (let j = 0; j < grid.length; j++) {
      if (numbersGrid[i][j] === null)
        continue;

      const [number, direction, direction2] = numbersGrid[i][j];

      if ([direction, direction2].includes('across'))
        definitions.across.push(number);
    }
  }

  // down definitions
  for (let j = 0; j < grid.length; j++) {
    for (let i = 0; i < grid.length; i++) {
      if (numbersGrid[i][j] === null)
        continue;

      const [number, direction, direction2] = numbersGrid[i][j];

      if ([direction, direction2].includes('down'))
        definitions.down.push(number);
    }
  }

  return definitions;
};

export const isFirstCellOfWord = (x, y, grid) => {
  return mapGridNumbers(grid)[x][y] !== null;
}

export const isFirstCellOfWordWithDirection = (x, y, direction, grid) => {
  let currentCell = grid[x][y];
  let previousCell, nextCell;

  try {
    previousCell = direction === 'across' ? grid[x][y-1] : grid[x-1][y];
  } catch (e) {}

  try {
    nextCell = direction === 'across' ? grid[x][y+1] : grid[x+1][y];
  } catch (e) {}

  // if current cell is black, not a word start
  if (currentCell === '#')
    return false;

  // if next cell is black or not exists, not a word start
  if (nextCell === '#' || typeof nextCell === 'undefined')
    return false;

  // if previous cell exists and is not black, not a word start
  if (previousCell !== '#' && typeof previousCell !== 'undefined')
    return false;

  return true;
}

export const getWordNumber = (x, y, grid) => {
  const gridNumber = mapGridNumbers(grid)[x][y];
  return gridNumber === null ? null : gridNumber[0];
}

export const getFirstCellOfWordByNumber = (number, grid) => {
  const numbersGrid = mapGridNumbers(grid);

  for (let i = 0; i < grid.length; i++) {
    for (let j = 0; j < grid.length; j++) {
      if (numbersGrid[i][j] && numbersGrid[i][j][0] === number)
        return { x: i, y: j };
    }
  }
}

export const belongToWordNumber = (x, y, direction, grid) => {
  const numbersGrid = mapGridNumbers(grid);

  if (numbersGrid[x][y] && (numbersGrid[x][y][1] === direction || numbersGrid[x][y][2] === direction))
    return numbersGrid[x][y];

  // move to previous cell depending on direction until find a word number
  let previousCell = direction === 'across' ? { x, y: y - 1 } : { x: x - 1, y };

  // if end of board reached, return null
  if (previousCell.x < 0 || previousCell.y < 0)
    return null;

  // if previous cell is black, return null
  if (grid[previousCell.x][previousCell.y] === '#')
    return null;

  return belongToWordNumber(previousCell.x, previousCell.y, direction, grid);
}

export const isWordComplete = (number, direction, grid) => {
  const firstCell = getFirstCellOfWordByNumber(number, grid);

  if (direction === 'across') {
    for (let i = firstCell.y; i < grid.length; i++) {
      if (grid[firstCell.x][i] === '')
        return false;

      if (grid[firstCell.x][i] === '#')
        break;
    }
  }

  if (direction === 'down') {
    for (let i = firstCell.x; i < grid.length; i++) {
      if (grid[i][firstCell.y] === '')
        return false;

      if (grid[i][firstCell.y] === '#')
        break;
    }
  }

  return true;
}

export const isWordCompleteByCell = (x, y, direction, grid) => {
  const wordNumber = belongToWordNumber(x, y, direction, grid);
  try {
    return isWordComplete(wordNumber[0], wordNumber[1], grid);
  } catch (e) {}

  return false;
}

window.mapGridNumbers = mapGridNumbers;
window.getDefinitionsArray = getDefinitionsArray;
window.isWordComplete = isWordComplete;
window.isWordCompleteByCell = isWordCompleteByCell;
window.getGridNumbersMap = getGridNumbersMap;
window.belongToWordNumber = belongToWordNumber;