import Modal from "./Modal";

const Subscribe = ({ onClose, isOpened }) => {
  return (
    <Modal
      isOpened={isOpened}
      onClose={onClose}
      logo={false}
    >
      <h3>Reçois les prochains Motif par email</h3>
      <iframe src="https://gosecure.wisembly.com/wgt/i2zv/x1gx/form?c=198205b6" width="100%" height="260" frameBorder="0" scrolling="no"></iframe>
    </Modal>
  )
};

export default Subscribe;