import URI from "urijs";
import {
  belongToWordNumber,
  getDefinitionsArray,
  getGridNumbersMap,
  isWordCompleteByCell,
} from "./grid";

const { autojump } = new URI().search(true);

const AUTOJUMP = !!autojump || false;

// move to next cell and change direction if needed and skip filled cells
export const moveToNextCell = (selected, selectedDirection, grid, jump = false) => {
  console.log('moveToNextCell', selected, selectedDirection);

  let nextSelected,
    nextSelectedDirection = selectedDirection;

  const isGridCompleted = grid.every(row => row.every(cell => cell !== ''));

  // if grid is completed, don't move
  if (isGridCompleted)
    return [selected, selectedDirection];

  // if end of board reached, move to first cell first word in the opposite direction
  if (selected.y === grid.length - 1 && selected.x === grid.length - 1) {
    if (grid[0][0] !== '')
      return moveToNextCell({ x: 0, y: 0 }, selectedDirection === 'across' ? 'down' : 'across', grid, jump);

    return [{ x: 0, y: 0 }, selectedDirection === 'across' ? 'down' : 'across'];
  }

  // next cell across
  if (selectedDirection === 'across' && selected.y < grid.length - 1)
    nextSelected = { x: selected.x, y: selected.y + 1 };

  // next cell down
  if (selectedDirection === 'down' && selected.x < grid.length - 1)
    nextSelected = { x: selected.x + 1, y: selected.y };

  // reached end of row, move to next row
  if (selectedDirection === 'across' && selected.y === grid.length - 1 && selected.x < grid.length - 1)
    nextSelected = { x: selected.x + 1, y: 0 };

  // reached end of column, move to first cell of the row
  if (selectedDirection === 'down' && selected.x === grid.length - 1 && selected.y < grid.length - 1)
    nextSelected = { x: 0, y: selected.y + 1 };

  // if next cell is not empty and auto jump, recursively move to next one
  // if next cell is black, recursively move to next one
  if (((AUTOJUMP || jump) && grid[nextSelected.x][nextSelected.y] !== '') || grid[nextSelected.x][nextSelected.y] === '#')
    return moveToNextCell(nextSelected, selectedDirection, grid, jump);

  // check if next cell belongs to a word
  const nextWordNumber = belongToWordNumber(nextSelected.x, nextSelected.y, selectedDirection, grid);

  // if next cell does not belong to a word, move again
  if (!nextWordNumber)
    return moveToNextCell(nextSelected, selectedDirection, grid, true);

  return [nextSelected, nextSelectedDirection];
};

// move to previous cell and change direction if needed. Do not skip filled cells
export const moveToPreviousCell = (selected, selectedDirection, grid) => {
  console.log('moveToPreviousCell', selected, selectedDirection);

  let nextSelected, nextSelectedDirection = selectedDirection;

  // if end of board reached, move to first cell first word in the opposite direction
  if (selected.y === 0 && selected.x === 0) {
    if (grid[grid.length - 1][grid.length - 1] !== '')
      return moveToPreviousCell({ x: grid.length - 1, y: grid.length - 1 }, selectedDirection === 'across' ? 'down' : 'across', grid);

    return [{ x: grid.length - 1, y: grid.length - 1 }, selectedDirection === 'across' ? 'down' : 'across'];
  }

  if (selectedDirection === 'across' && selected.y > 0)
    nextSelected = { x: selected.x, y: selected.y - 1 };

  if (selectedDirection === 'down' && selected.x > 0)
    nextSelected = { x: selected.x - 1, y: selected.y };

  if (selectedDirection === 'across' && selected.y === 0 && selected.x > 0)
    nextSelected = { x: selected.x - 1, y: grid.length - 1 };

  if (selectedDirection === 'down' && selected.x === 0 && selected.y > 0)
    nextSelected = { x: grid.length - 1, y: selected.y - 1 };

  if (selectedDirection === 'across' && selected.y === 0 && selected.x === 0)
    nextSelected = { x: grid.length - 1, y: grid.length - 1 };

  if (selectedDirection === 'down' && selected.x === 0 && selected.y === 0)
    nextSelected = { x: grid.length - 1, y: grid.length - 1 };

  // if previous cell is black, recursively move to previous one
  if (grid[nextSelected.x][nextSelected.y] === '#')
    return moveToPreviousCell(nextSelected, selectedDirection, grid);

  return [nextSelected, nextSelectedDirection];
}

export const moveToNextDefinition = (wordNumber, selectedDirection, grid) => {
  console.log('moveToNextDefinition', wordNumber, selectedDirection);

  const numbersMap = getGridNumbersMap(grid);
  const definitions = getDefinitionsArray(grid);

  // move to next definition in definitions array
  const index = definitions[selectedDirection].indexOf(wordNumber);

  if (index < definitions[selectedDirection].length - 1 )
    return [numbersMap[`${definitions[selectedDirection][index + 1]}-${selectedDirection}`], selectedDirection];

  // if end of definitions array reached, move to first definition in the opposite direction
  const newDirection = selectedDirection === 'across' ? 'down' : 'across';
  const newWordNumber = definitions[newDirection][0];

  return [numbersMap[`${newWordNumber}-${newDirection}`], newDirection];
};

export const moveToPreviousDefinition = (wordNumber, selectedDirection, grid) => {
  console.log('moveToPreviousDefinition', selectedDirection, selectedDirection);

  const numbersMap = getGridNumbersMap(grid);
  const definitions = getDefinitionsArray(grid);

  // move to previous definition in definitions array
  const index = definitions[selectedDirection].indexOf(wordNumber);

  if (index > 0 )
    return [numbersMap[`${definitions[selectedDirection][index - 1]}-${selectedDirection}`], selectedDirection];

  // if end of definitions array reached, move to last definition in the opposite direction
  const newDirection = selectedDirection === 'across' ? 'down' : 'across';
  const newWordNumber = definitions[newDirection][definitions[newDirection].length - 1];

  return [numbersMap[`${newWordNumber}-${newDirection}`], newDirection];
}

export const moveToNextUncompletedDefinition = (selected, selectedDirection, grid) => {
  console.log('moveToNextUncompletedDefinition', selected, selectedDirection);
  const completed = isWordCompleteByCell(selected.x, selected.y, selectedDirection, grid);

  if (completed) {
    const wordNumber = belongToWordNumber(selected.x, selected.y, selectedDirection, grid);
    const [nextSelected, nextDirection] = moveToNextDefinition(wordNumber[0], selectedDirection, grid);
    return moveToNextUncompletedDefinition(nextSelected, nextDirection, grid);
  }

  return [selected, selectedDirection];
}