import LogoLight from './images/logo.svg';
import LogoDark from './images/logo-dark.svg';
import LogomarkLight from './images/logomark.svg';
import LogomarkDark from './images/logomark-dark.svg';
import { useDarkMode } from 'usehooks-ts';

const Logo = ({ type }) => {
  const { isDarkMode } = useDarkMode()

  if (type === "logomark") {
    return (
      <img src={isDarkMode ? LogomarkDark : LogomarkLight} alt="Logo de Motif" className="Logo Logomark" />
    );
  
  }

  return (
    <img src={isDarkMode ? LogoDark : LogoLight} alt="Logo de Motif" className="Logo" />
  );
}

export default Logo;