import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { decrypt } from "./utils/crypt";

const getOpponentStatus = (x, y, elapsedTime, opponentHistory) => {
  const cellsHistory = (opponentHistory?.history || [])
    .filter(history => history.elapsedTime < elapsedTime && history.x === x && history.y === y);

  if (cellsHistory.length === 0)
    return null;

  const lastHistory = cellsHistory[cellsHistory.length - 1];

  return {
    isValid: lastHistory.isValid,
    hint: lastHistory.hint,
  }
}

const Cell = ({ x, y, value, wordNumber, isSelected, isFromSelectedWord, onClick, size, elapsedTime, opponentHistory }) => {
  const hints = useSelector(state => state.app.hints);
  const hintsKey = useSelector(state => state.app.hintsKey);
  const autoVerify = useSelector(state => state.app.autoVerify);
  const reveals = useSelector(state => state.app.reveals);

  const opponentStatus = getOpponentStatus(x, y, elapsedTime, opponentHistory);

  let isValid = true;
  let isRevealed = false;

  reveals.forEach(reveal => {
    if (reveal.x === x && reveal.y === y)
      isRevealed = true;
  });

  if (value !== '' && hints[x] && hints[x][y] && decrypt(hintsKey || dayjs().format('YYYY-MM-DD'), hints[x][y]) !== value)
    isValid = false;

  const cellStyle = {
    width: `${size}px`,
    height: `${size}px`,
    lineHeight: `${size}px`,
  };

  let className = `Cell ${isSelected ? 'selected' : ''} ${isFromSelectedWord ? 'row-selected' : ''} ${value === '#' ? 'black' : ''} ${autoVerify && !isValid ? 'invalid' : ''} ${isRevealed ? 'revealed' : ''} ${isValid ? 'Cell--success' : ''}`;

  if (opponentStatus !== null && value === '')
    className += ` ${opponentStatus.isValid ? 'Cell--opponent-success' : 'Cell--opponent-fail'}`;

  if (opponentStatus !== null && opponentStatus.hint) 
    className += ` Cell--opponent-hint`;

  return (
    <div
      className={className}
      data-x={x}
      data-y={y}
      onClick={onClick}
      style={cellStyle}
    >
      {value !== '#' && (
        <>
          {wordNumber && <div className="word-number">{wordNumber[0]}</div>}
          {value.toUpperCase()}
          {/* {`${x},${y}`} */}
        </>
      )}

      {opponentStatus !== null && opponentStatus.hint && (
        <div className="Revealed-Mark--opponent">
          <div className="Revealed-Dot--opponent"></div>
        </div>
      )}

      {isRevealed && (
        <div className="Revealed-Mark">
          <div className="Revealed-Dot"></div>
        </div>
      )}
    </div>
  )
}

export default Cell;