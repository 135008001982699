import URI from "urijs";
import Logo from "./Logo";
import Close from "./images/close.svg"
import CloseDark from "./images/close-dark.svg"
import { useDarkMode } from "usehooks-ts";

const Modal = ({ children, footer, logo = true, onClose, className, isOpened, showClose = true }) => {
  const { isDarkMode } = useDarkMode()
  const { embed } = (new URI(window.location.href)).search(true);
  const isEmbedded = typeof embed !== 'undefined';

  let CloseIcon = isDarkMode ? CloseDark : Close;

  return (
    <div className={`Modal ${className || ""} ${isOpened ? "in" : "out"}`}>
      <div className="ModalOverlay" />
      <div className={`ModalWrapper ${isEmbedded ? "is-embedded" : "not-embedded"}`}>
        {showClose &&
          <div className="ModalHeader">
            <div className="CloseBtn" onClick={() => onClose()}>
              <img src={CloseIcon} alt="Fermer" className="Icon" />
            </div>
          </div>
        }
        <div className="ModalContent">
          <div className="ModalBody">
            {logo && !isEmbedded && <Logo type="logomark" />}
            {children}
          </div>
        </div>
        {footer && (
          <div className="ModalFooter">
            {footer}
          </div>
        )}
      </div>
    </div>
  );
}

export default Modal;