import Modal from "./Modal";

const Pause = ({ onClose, isOpened }) => {
  return (
    <Modal
      className="Pause"
      isOpened={isOpened}
      footer={
        <div className="BtnGroup">
          <div className="ActionBtn Button--primary" onClick={onClose}>
            Reprendre la partie
          </div>
        </div>
      }
      onClose={onClose}
    >
      <h1>Ta partie est en pause</h1>
      <p>Il te reste quelques mots à trouver. Veux-tu reprendre où tu t’étais arrêté ?</p>
    </Modal>
  )
};

export default Pause;