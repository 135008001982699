import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/All.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { PostHogProvider} from 'posthog-js/react'
import {
  REACT_APP_PUBLIC_POSTHOG_HOST,
  REACT_APP_PUBLIC_POSTHOG_KEY
} from './config';

const root = ReactDOM.createRoot(document.getElementById('root'));

// do not wrap with posthog on localhost
const PostHog = ({ children }) => {
  if (window.location.hostname === 'localhost') {
    console.log('PostHog disabled on localhost');
    return children;
  }

  return (
    <PostHogProvider
      apiKey={REACT_APP_PUBLIC_POSTHOG_KEY}
      options={{
        api_host: REACT_APP_PUBLIC_POSTHOG_HOST
      }}
    >
      {children}
    </PostHogProvider>
  );
}

root.render(
  <React.StrictMode>
    <PostHog>
      <Provider store={store}>
        <App />
      </Provider>
    </PostHog>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
