import React, { useEffect, useState } from 'react';

import './Timer.css';
import { useDispatch, useSelector } from 'react-redux';
import { setPause, setTick, incrElapsedTime } from './store';

export const formatTime = (time, full = false) => {
  let hours = Math.floor(time / 3600);
  let minutes = Math.floor((time - hours * 3600) / 60);
  let seconds = time - hours * 3600 - minutes * 60;

  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;
  if (seconds < 10) seconds = `0${seconds}`;

  if (hours === '00' && !full) return `${minutes}:${seconds}`;
  if (hours === '00' && full) return `${minutes} minute${minutes === '01' ? '' : 's'} et ${seconds} seconde${seconds === '01' ? '' : 's'}`;

  if (full)
    return `${hours} heure${hours === '01' ? '' : 's'}, ${minutes} minute${minutes === '01' ? '' : 's'} et ${seconds} seconde${seconds === '01' ? '' : 's'}`;

  return `${hours}:${minutes}:${seconds}`;
}

const INNACTIVITY_TRERSHOLD = 1000 * 10;
let innactivityTimeout = null;

const Timer = ({ emitter, isEmbedded }) => {
  const dispatch = useDispatch();

  const success = useSelector(state => state.app.success);
  const elapsedTime = useSelector(state => state.app.elapsedTime);
  const pause = useSelector(state => state.app.pause);

  const [showPenalty, setShowPenalty] = useState(false);

  const timer = () => dispatch(setTick());

  useEffect(() => {
    window.onfocus = () => {
      if (!innactivityTimeout) return;
      clearTimeout(innactivityTimeout);
    };

    window.onblur = () => {
      // if queryArg ?noPause is present, never pause
      if (new URLSearchParams(window.location.search).has('noPause')) return;

      console.log('on blur')
      // auto pause after 10 seconds of inactivity (un-focus tab or window)
      innactivityTimeout = setTimeout(() => dispatch(setPause(true)), INNACTIVITY_TRERSHOLD);
    };

    // add 20 seconds malus on reveal
    const onReveal = () => {
      dispatch(incrElapsedTime(20));
      setShowPenalty(true);
    };

    emitter.on('onReveal', onReveal);

    return () => {
      emitter.off('onReveal', onReveal);
    };
  }, []);

  useEffect(() => {
    if (!showPenalty) return;
    setTimeout(() => setShowPenalty(false), 1000);
  }, [showPenalty]);

  useEffect(() => {
    if (pause && !isEmbedded) return;
    if (success) return;

    const interval = setInterval(timer, 1000);
    return () => clearInterval(interval);
  }, [pause, success]);

  return (
    <div className="Timer">

      <div onClick={() => dispatch(setPause(true))} className="TimerCountdown">{formatTime(elapsedTime)}</div>

      {showPenalty && <div className="Penalty">+20s</div>}

    </div>
  )
};

export default Timer;