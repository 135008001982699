import URI from "urijs"

// this file is used to look into the url and get the current day the user wants
// day could be given by queryArg or pahtName
// queryArg: ?date=2021-09-01
// pathname: /2021-09-01
// if both used, queryArg wins
// queryArg could use anydate while pathname can only use last 5 days!
export const getCurrentDay = (emitter = null, isTooOld = false) => {
  const url = new URI(window.location.href);
  const { date } = url.search(true);
  const pathDay = url.pathname().replaceAll('/', '');

  if (date)
    return date

  if (pathDay) {
    // if pathDay is a valid date and less than 5 days ago
    const today = new Date()
    const pathDate = new Date(pathDay)
    const diffTime = Math.abs(today - pathDate)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    if (diffDays <= 6)
      return pathDay

    if (!isTooOld && emitter)
      emitter.emit('too_old', pathDay);

    return null
  }

  return null
}


export const getMode = () => {
  const url = new URI(window.location.href);
  const { mode,  battle_code } = url.search(true);

  if (mode === `battle`)
    return { mode, battle_code }

  return { mode: `daily` }
}