import Modal from "./Modal";

const Share = ({ hasShared = false, onClose, isOpened, onSubscribeClicked, onDownload }) => {
  return (
    <Modal
      isOpened={isOpened}
      onClose={onClose}
      logo={false}
    >
      {hasShared &&
        <>
          <div className="Success-Shared">
            <div className="Icon">📨</div>
            <p>Pour partager ton score, colle le lien qui est maintenant dans ton presse-papier dans l'appli de ton choix.</p>
            <p>Merci pour ton partage 🙏</p>
          </div>
          <hr />
        </>
      }
      <h3>Rejoins la communauté Motif</h3>
      <div className="BtnGroup">
        <div
          className="Button ActionBtn"
          onClick={() => {
            onSubscribeClicked();
            onClose();
          }}
        >Reçois les prochaines grilles par email</div>
        <a className="Button ActionBtn" target="_blank" href="https://chat.whatsapp.com/EHcUBN4EGvh46IWzlAMj9T">
          Suis Motif sur Whatsapp
        </a>
        <a className="Button ActionBtn" onClick={onDownload} target="_blank" href="https://apps.apple.com/fr/app/motif-mots-crois%C3%A9s-5x5/id6479329303">
          Télécharges Motif sur l'App Store
        </a>
        <a className="Button ActionBtn" onClick={onDownload} target="_blank" href="https://play.google.com/store/apps/details?id=com.omagad.motif">
          Télécharges Motif sur le Play Store
        </a>
      </div>
    </Modal>
  )
};

export default Share;