import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setSelected, setSelectedDirection } from './store';

import { MdNavigateNext } from "react-icons/md";
import { MdNavigateBefore } from "react-icons/md";
import { moveToNextCell, moveToNextDefinition, moveToPreviousCell, moveToPreviousDefinition } from './utils/move';
import { belongToWordNumber, getDefinitionsArray, isWordComplete, mapGridNumbers } from './utils/grid';
import { capitalizeFirstLetter } from './utils/string';

const Definitions = ({ position }) => {
  const dispatch = useDispatch();

  const grid = useSelector(state => state.app.grid);
  const definitions = useSelector(state => state.app.definitions);
  const selected = useSelector(state => state.app.selected);

  const selectedDirection = useSelector(state => state.app.selectedDirection);
  const currentWordNumber = belongToWordNumber(selected.x, selected.y, selectedDirection, grid);

  const definitionsNumbersArray = getDefinitionsArray(grid);

  const onChange = (direction) => {
    let [next, nextDirection] = direction === 'previous' ? moveToPreviousDefinition(currentWordNumber[0], selectedDirection, grid) : moveToNextDefinition(currentWordNumber[0], selectedDirection, grid);

    if (next === false)
      return;

    if (grid[next.x][next.y] !== '') {
      const skip = direction === 'previous' ? moveToPreviousCell(next, nextDirection, grid, true) : moveToNextCell(next, nextDirection, grid, true);
      next = skip[0];
      nextDirection = skip[1];
    }

    dispatch(setSelected(next));
    dispatch(setSelectedDirection(nextDirection));
  };

  const onSelectWord = (index, direction) => {
    const gridNumbers = mapGridNumbers(grid);

    let nextSelected = {};

    for (let i = 0; i < grid.length; i++) {
      for (let j = 0; j < grid.length; j++) {
        if (gridNumbers[i][j] && gridNumbers[i][j][0] === definitionsNumbersArray[direction][index]) {
          nextSelected = { x: i, y: j };
          break;
        }
      }
    }

    // if word not complete, and cell not empty, move to next cell
    if (grid[nextSelected.x][nextSelected.y] !== '' && !isWordComplete(index + 1, direction, grid))
      nextSelected = moveToNextCell(nextSelected, direction, grid, true)[0];

    dispatch(setSelected(nextSelected));
    dispatch(setSelectedDirection(direction));
  };

  if (!definitions?.across) return null;

  if (position === "right") {
    return (
      <div className="Definitions Definitions--vertical">
        <div className="Definitions-Content">
          {Object.keys(definitions).map((direction, index) => (
            <React.Fragment key={index}>
              <div className={`Definitions-Separator Definitions-Separator--${'across' === direction ? "first" : "last"}`}>{direction === "across" ? "Horizontalement" : "Verticalement"}</div>
              <div className={`Definitions-List Definitions-List--${'across' === direction ? "first" : "last"}`}>
                {definitions[direction].map((definition, index) => {
                  let isSelected;
                  const definitionNumber = definitionsNumbersArray[direction][index];

                  try {
                    isSelected = definitionsNumbersArray[direction].indexOf(currentWordNumber[0]) === index && selectedDirection === direction;
                  } catch (e) {}

                  return <div onClick={() => onSelectWord(index, direction)} key={index} className={`Definition ${isSelected ? 'Definition--selected' : ''}`}>
                    <div className="Definition-Counter">{definitionNumber}</div>
                    {capitalizeFirstLetter(definition)}
                  </div>
                })}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    )
  }

  let currentDefinition = '';

  try {
    currentDefinition = definitions[selectedDirection][definitionsNumbersArray[selectedDirection].indexOf(currentWordNumber[0])];
  } catch (e) {}

  return (
    <div className="Definitions Definitions--horizontal">
      <div className="Previous" onClick={() => onChange('previous')}>
        <MdNavigateBefore size={40} style={{ color: "black" }} />
      </div>
      <div className="Definition">
        {currentWordNumber && (
          <span>{currentWordNumber[0]} - {capitalizeFirstLetter(currentDefinition)}</span>
        )}
      </div>
      <div className="Next" onClick={() => onChange('next')}>
        <MdNavigateNext size={40} style={{ color: "black" }} />
      </div>
    </div>
  )
};

export default Definitions;