import { useSelector } from "react-redux";
import Modal from "../Modal";

import './Stats.css';
import URI from "urijs";

const convertSecondsToHumanReadable = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  return `${hours > 0 ? `${hours}h ` : ''}${minutes > 0 ? `${minutes}m ` : ''}${remainingSeconds}s`;
};

const Block = ({ title, value, percent }) => (
  <div className="Block">
    <div className="BlockTitle">{title}</div>
    <div className="BlockValue">
      {value}
      {!!percent && <div className="BlockPercent">{percent}%</div>}
    </div>
  </div>
);

const Stats = () => {
  const totalGridsPlayed = useSelector(state => state.stats.totalGridsPlayed);
  const totalGridsCompleted = useSelector(state => state.stats.totalGridsCompleted);

  const currentStreak = useSelector(state => state.stats.currentStreak);
  const longestStreak = useSelector(state => state.stats.longestStreak);

  const totalElapsedTime = useSelector(state => state.stats.totalElapsedTime);
  const totalHintsUsed = useSelector(state => state.stats.totalHintsUsed);

  const { embed } = (new URI(window.location.href)).search(true);
  const isEmbedded = typeof embed !== 'undefined';

  return (
    <Modal className="Stats large" isOpened={true}>
      <h1>Statistiques de jeu</h1>

      <div className="Stats">
        <div className="StatsRow">
          <Block title="Parties jouées" value={totalGridsPlayed} />
          <Block title="Parties terminées" value={totalGridsCompleted} percent={totalGridsPlayed > 0 ? Math.round(totalGridsCompleted / totalGridsPlayed * 10000) / 100  : null}/>
        </div>

        <div className="StatsRow">
          <Block title="Série en cours" value={currentStreak} />
          <Block title="Plus longue série" value={longestStreak} />
        </div>

        <div className="StatsRow">
          <Block title="Temps total passé à réfléchir" value={convertSecondsToHumanReadable(totalElapsedTime)} />
          <Block title="Nombre total d'indices utilisés" value={totalHintsUsed} />
        </div>

      </div>

      {!isEmbedded && (
        <>
          <p style={{ fontSize: "12px" }}>Ces statistiques s'appuient sur le localStorage de votre appareil, et sont par conséquent stoquées uniquement sur ce dernier. Elles seront perdues si vous videz votre cache et vos cookies.</p>

          <div style={{ marginTop: '25px' }}>
            <a href="/" className="Button Button--primary">Retour au jeu</a>
          </div>
        </>
      )}
    </Modal>
  )
};

export default Stats;